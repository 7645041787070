
export function setItem(key, value) {
  return window.sessionStorage.setItem(key, JSON.stringify(value) || '');
};

export function getItem(key) {
  try {
    return JSON.parse(window.sessionStorage.getItem(key));
  } catch (err) {
    window.sessionStorage.clear();
  }
};

export function removeItem(key) {
  return window.sessionStorage.removeItem(key);
}
