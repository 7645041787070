import { Button, FontIcon } from 'react-md';
import PropTypes from 'prop-types';
import styles from './SubHeader.module.scss';

const SubHeader = ({ backAction, label, subText, children }) => {
  return (
    <div className={styles.container}>
      {backAction &&
        <div>
          <Button className={styles.backAction} onClick={backAction} buttonType="icon">
            <FontIcon>
              arrow_back
            </FontIcon>
          </Button>
        </div>
      }
      <div>
        {children ?
          children :
          <>
            <h2 className={styles.label}>{label}</h2>
            <p className={styles.subText}>{subText}</p>
          </>
        }
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  backAction: PropTypes.func,
  children: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default SubHeader;
