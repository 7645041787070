import { Button } from 'react-md';
import { useNavigate } from 'react-router-dom';
import { isNotEmpty } from '../../utils';
import { SearchInput, SwitchInput } from '../';
import * as routes from '../../constants/routes';
import styles from './VIPSListActionBar.module.scss';

const VIPSListActionBar = ({
  handleSwitch,
  searchTerm,
  selected,
  setSearchTerm,
  setSelectedVips,
  toggled,
  vips
}) => {
  const navigate = useNavigate();
  const selectAll = () => setSelectedVips(vips.map(vip => vip.id));
  const deselectAll = () => setSelectedVips([]);

  return (
    <div className="ab-container">
      <SearchInput
        className="ab-search"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search by VIP Name"
      />
      <div className="ab-middle">
        <SwitchInput
          control={toggled}
          id="switch-duplicate"
          onChange={handleSwitch}
          label={toggled ? 'Only show suspected duplicates' : 'Filter suspected duplicates'}
          labelClassName={toggled ? styles.on : styles.off}
        />
      </div>
      <div className="ab-actions">
        <Button
          className="button ghost"
          onClick={() => navigate(routes.CREATE_VIPS)}
        >
          create new vip
        </Button>
        {!isNotEmpty(vips) ? null : selected.length === vips.length ?
          <Button className="button ghost" onClick={deselectAll}>Deselect All</Button> :
          <Button className="button ghost" onClick={selectAll}>Select All</Button>
        }
      </div>
    </div>
  );
};

export default VIPSListActionBar;
