import './FilterBar.scss';

const FilterBar = ({ children, className }) => {
  const renderChildren = () => {
    if (Array.isArray(children)) {
      return children.map((child, index) => {
        if (child) {
          return (
            <div tabIndex="0" key={index} className="filter-chip-container">
              {child}
            </div>
          );
        }

        return null;
      });

    } else {
      return <div tabIndex="0" className="filter-chip-container">{children}</div>;
    }
  };

  return <div className={`filter-bar ${className ? className : ''}`}>{renderChildren()}</div>;
};

export default FilterBar;
