import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MessageQueue, Configuration } from 'react-md';
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client';
import { App } from './components';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'include'
  }),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          event: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            }
          }
        }
      }
    }
  })
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Configuration>
      <MessageQueue portal id="snackbar">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MessageQueue>
    </Configuration>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
