import { Button, TextIconSpacing, FontIcon } from 'react-md';
import { SearchInput, useLayoutContext } from '../';
import styles from './TopActionBar.module.scss';

const TopActionBar = ({ events, selected, setView, listView, update, children, setSearchTerm, searchTerm, loading }) => {
  const { updateEvents } = useLayoutContext();

  const selectAll = () => {
    updateEvents(events.map(event => event.id));
    update();
  };

  const deselectAll = () => {
    updateEvents([]);
    update();
  };

  return (
    <div className="ab-container">
      <SearchInput
        disabled={loading}
        className="ab-search"
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        placeholder="Search by event name"
      />
      <div className="ab-middle">
        {children}
      </div>
      <div className="ab-actions">
        <Button
          disabled={loading}
          id="card-view-toggle"
          className={styles.toggle}
          onClick={setView}
        >
          <TextIconSpacing
            icon={
              <FontIcon>{listView ? 'view_module' : 'view_list'}</FontIcon>
            }
          >
            {listView ? 'Card View' : 'List View'}
          </TextIconSpacing>
        </Button>
        {selected?.length === events?.length ?
          <Button disabled={loading} className="button ghost" onClick={deselectAll}>Deselect All</Button> :
          <Button disabled={loading} className="button ghost" onClick={selectAll}>Select All</Button>
        }
      </div>
    </div>
  );
};

export default TopActionBar;
