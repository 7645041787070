import styles from './Footer.module.scss';

const Footer = ({ children }) => {
  return (
    <footer className={styles.footer}>
      <div className="wrapper">
        <div className={styles.actions}>
          {children}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
