import { lazy, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Header } from '@parkhub/parkhub-ui';
import { signOut, getAuthUser, createFeatureAccess } from '../../utils';
import * as routes from '../../constants/routes';
import ParkhubLogo from '../../assets/parkhub-vip-logo-gray.svg';
import { LayoutContextProvider } from './LayoutContext';

const VIPS = lazy(() => import('../VIPS/VIPS'));
const CreateVIPS = lazy(() => import('../CreateVIPS/CreateVIPS'));
const EditVIP = lazy(() => import('../EditVIP/EditVIP'));
const Events = lazy(() => import('../Events/Events'));
const Lots = lazy(() => import('../Lots/Lots'));
const Csv = lazy(() => import('../Csv/Csv'));

const Layout = () => {
  const access = createFeatureAccess();
  const navigate = useNavigate();
  const authUser = getAuthUser();

  return (
    <>
      <Header
        authUser={authUser}
        access={access}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={239}
        appMenuActive
        logoClick={() => navigate(routes.ROOT)}
      />
      <main id="layout">
        <LayoutContextProvider>
          <Routes>
            <Route
              path={routes.VIPS}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <VIPS />
                </Suspense>
              }
            />
            <Route
              path={routes.CREATE_VIPS}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <CreateVIPS />
                </Suspense>
              }
            />
            <Route
              path={routes.EDIT_VIP}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <EditVIP />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.ROOT}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Events />
                </Suspense>
              }
            />
            <Route
              path={routes.LOTS}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Lots />
                </Suspense>
              }
            />
            <Route
              path={routes.CSV}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Csv />
                </Suspense>
              }
            />
          </Routes>
        </LayoutContextProvider>
      </main>
    </>
  );
};

export default Layout;
