import _ from 'lodash';

export * from './sessionStorage';
export * from './authentication';
export * from './fuseOptions';
export * from './time';

export const idFormatter = (string) => string.toLowerCase().split(' ').join('-');

// // returns object formatted for use with checklists
// export function createFilterList(data, keyName, filterValues) {
//   // if filterValues is passed in and has a value it will be checked for when creating the list
//   const checked = (value) => filterValues && filterValues.length > 0 ? filterValues.includes(value) : true;

//   return _.uniqBy(data.map((item) => ({ name: item[keyName], id: item.id, selected: checked(item.id) })), 'name');
// }

export function createFilterList(data, keyName, defaultSelectedValue = true) {
  if (keyName) {
    return _.uniqBy(
      data.map(item => ({ name: item[keyName], id: item.id, selected: defaultSelectedValue })),
      'name'
    );

  } else {
    return _.uniqBy(
      data.map(item => ({ name: item, id: item, selected: defaultSelectedValue })),
      'name'
    );
  }
}

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}


// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

// get value of object by path. Path can be nested. example: resolvePropDepth('name.first', obj)
export function resolvePropDepth(path, obj) {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || this);
}

// truncate string with max character count
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}
