import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { filter, map, findIndex, find, some, isEmpty } from 'lodash';
import Fuse from 'fuse.js';
import cx from 'classnames';
import { FontIcon, TextField, Checkbox, Button } from 'react-md';
import { FUSE_OPTIONS, idFormatter, truncate, orderByKey } from '../../utils';
import './FilterChip.scss';

const FilterChip = ({ onApply, onRemove, filterKey, label, direction, labelKey, data }) => {
  const [expanded, setExpanded] = useState(false);
  const [initialData, setInitialData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeItems, setActiveItems] = useState([]);
  const element = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    // shameful hack - style container to prevent break in flow
    const containerElement = element.current.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 2000);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  function handleClick(e) {
    if (element.current.contains(e.target)) {
      return;
    }

    if (e.target.id === 'clear-filter-btn') {
      selectAll();
    }

    setExpanded(false);
    setSearchTerm('');
  }

  function countChecked() {
    const checkedOptions = activeItems?.length;

    if (checkedOptions === initialData.length || activeItems?.length === 0) {
      return 'all';
    }

    return checkedOptions;
  }

  function selectAll() {
    setInitialData(initialData.map(({ name, id }) => ({ name, id, selected: true })));
  }

  function deselectAll() {
    setInitialData(initialData.map(({ name, id }) => ({ name, id, selected: false })));
  }

  function isRemovable() {
    if (activeItems?.length) {
      return filter(initialData, { selected: true }).length < initialData.length;
    }

    return false;
  }

  function handleSetExpand(value, e) {
    e.stopPropagation();
    setSearchTerm('');
    setExpanded(value);
  }

  function handleApply(value, e) {
    e.stopPropagation();
    setExpanded(value);

    const selected = map(filter(initialData, { selected: true }), (item) => item.id);
    const applied = selected.length ? selected : null;

    setActiveItems(applied || []);
    onApply(applied, filterKey);
  }

  function handleRemove(e) {
    e.stopPropagation();
    setInitialData(initialData.map(({ name, id }) => ({ name, id, selected: false })));

    setActiveItems([]);
    onApply(null, filterKey);

    if (onRemove) {
      onRemove(null, filterKey);
    }
  }

  function handleOnChangeCheckbox(v, item) {
    const index = findIndex(initialData, obj => obj.id === item.id);
    const newList = [...initialData];
    newList[index].selected = v;

    setInitialData(newList);
  }

  function handleOnChangeSelectAll(checked) {
    return checked ? selectAll() : deselectAll();
  }

  const isAllSelected = !some(initialData, ['selected', false]);

  function createFilteredList() {
    const searchList = initialData;
    const fuse = new Fuse(searchList, FUSE_OPTIONS);
    const results = fuse.search(searchTerm);
    const filteredWithCheckState = results.map(result => find(initialData, ['id', result.item.id]));

    return searchTerm === '' ? initialData : filteredWithCheckState;
  }

  function handleSearch(v) {
    setSearchTerm(v);
  }

  const filteredList = orderByKey(createFilteredList(), 'name', 'asc');

  return (
    <>
      {!expanded &&
        <div ref={element} id={label}>
          <div className={cx('filter-chip', { removable: isRemovable() })} onClick={(e) => handleSetExpand(true, e)}>
            <div className="filter-chip-header">
              <span className="chip-label">
                {`${label} (${countChecked()})`}
              </span>
              <>
                {isRemovable() &&
                  <Button
                    id={`${idFormatter(label)}-filter-cancel`}
                    className="chip-remove-button"
                    onClick={(e) => handleRemove(e)}
                    buttonType="icon"
                    aria-label="remove"
                  >
                    <FontIcon>cancel</FontIcon>
                  </Button>
                }
                {!expanded && !isRemovable() &&
                  <FontIcon
                    id={`${idFormatter(label)}-filter-drop-down`}
                    className="chip-icon"
                  >
                    arrow_drop_down
                  </FontIcon>
                }
              </>
            </div>
          </div>
        </div>
      }
      {expanded &&
        <div ref={element} id={label}>
          <div className={`filter-chip expanded expand-${direction}`}>
            <div className="filter-chip-header">
              <span className="chip-label">
                {label}
              </span>
              <Button
                id={`${idFormatter(label)}-filter-close-chip`}
                className="chip-close-icon"
                onClick={(e) => handleSetExpand(false, e)}
                buttonType="icon"
                aria-label="close"
              >
                <FontIcon>close</FontIcon>
              </Button>
            </div>
            <div className="filter-chip-dropdown">
              <TextField
                id={`${idFormatter(label)}-search-input`}
                className="filter-chip-search-input"
                placeholder="What are you looking for...?"
                theme="underline"
                label="Search"
                underlineDirection="left"
                rightChildren={<FontIcon>search</FontIcon>}
                onChange={(e) => handleSearch(e.currentTarget.value)}
              />
              <br />
              {!isEmpty(filteredList) &&
                <Checkbox
                  id={`${idFormatter(label)}-top-control-select`}
                  className="ph-checkbox top-control"
                  label="Select all"
                  name="select-all"
                  onChange={(e) => handleOnChangeSelectAll(e.target.checked)}
                  checked={isAllSelected}
                />
              }
              <div className="list-container">
                <ul className="chip-list">
                  {filteredList && filteredList.map((item, index) => (
                    <li
                      title={item[labelKey]}
                      key={`${item[labelKey]}-${index}`}
                    >
                      <Checkbox
                        id={`${idFormatter(label)}-check-input-${index}`}
                        className="ph-checkbox"
                        name={`${idFormatter(label)}-check-input-${index}`}
                        label={truncate(item[labelKey], 40)}
                        title={item[labelKey]}
                        onChange={(e) => handleOnChangeCheckbox(e.currentTarget.checked, item)}
                        checked={item.selected}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="filter-chip-action-container">
                <Button
                  disabled={filteredList.length === 0}
                  id={`${idFormatter(label)}-filter-apply`}
                  className="filter-chip-apply-button"
                  themeType="contained"
                  theme="primary"
                  onClick={(e) => handleApply(false, e)}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

FilterChip.propTypes = {
  direction: PropTypes.string, // open direction
  filterKey: PropTypes.string, // name of parent filter state property
  label: PropTypes.string.isRequired, //  Title displayed in filter
  labelKey: PropTypes.string, // property key to display the item name, sends (value, filterKey), defaults to 'name'
  onApply: PropTypes.func, // method to pass values to parent sends (value, filterKey)
  onRemove: PropTypes.func //optional function executed after onApply if remove button is clicked
};

FilterChip.defaultProps = {
  direction: 'left',
  labelKey: 'name'
};

export default FilterChip;
