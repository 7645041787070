import { useCallback, useEffect, useRef, useState } from 'react';
import { SessionDialog } from '@parkhub/parkhub-ui';
import { checkAuth, getSessionTime, refreshSession, signOut, signInAndRedirect } from '../../utils';
import { Layout } from '../';

const App = () => {
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const checkTime = useRef(false);

  const hide = () => {
    setSessionModalVisible(false);
  };

  const show = () => {
    setSessionModalVisible(true);
  };

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);
          const checkSessionTimeout = () => {
            if (!checkAuth()) {
              signInAndRedirect();

            } else {
              const minutes = getSessionTime();

              if (minutes > 1440) {
                setIsAuthorized(false);
                setSessionModalVisible(false);
                signOut('?expired=true');

              } else if (minutes > 1438 && minutes < 1440) {
                show();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        })
        .catch(err => console.error(err));
    }, [],
  );

  const checkLoggedIn = useCallback(
    () => {
      if (!checkAuth()) {
        signInAndRedirect();

      } else {
        setIsAuthorized(true);
        startTimeout();
      }
    }, [startTimeout],
  );

  useEffect(() => {
    checkLoggedIn();

    return () => {
      clearInterval(checkTime.current);
    };
  }, [checkLoggedIn]);

  return (
    <>
      {isAuthorized &&
        <>
          <Layout />
          <SessionDialog
            onHide={hide}
            visible={sessionModalVisible}
            onContinue={startTimeout}
            onTimeExpire={() => signOut('?expired=true')}
            onSignOut={signOut}
          />
        </>
      }
    </>
  );
};

export default App;
