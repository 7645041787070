import { createContext, useContext } from 'react';
import { setItem, getItem, removeItem } from '../../utils';

const LayoutContext = createContext([{}, () => { }]);
const storageKey = 'selectedEvents';

export const LayoutContextProvider = ({ children }) => {
  const getSelectedEvents = () => getItem(storageKey) || [];

  const updateEvents = (payload) => {
    setItem(storageKey, payload);
  };

  const clearEvents = () => {
    removeItem(storageKey);
  };

  return (
    <LayoutContext.Provider
      value={{
        getSelectedEvents,
        clearEvents,
        updateEvents
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutContextProvider');
  }

  return context;
};
