import { ActiveAscending, ActiveDescending, InactiveSort } from '../../assets/react-icons';
import cx from 'classnames';

const ThItem = ({
  children,
  activeSortKey,
  sortKey,
  direction,
  onClick,
  sortable,
  className,
  iconClassName
}) => {
  function handleClick() {
    onClick(sortKey, sortable);
  }

  return (
    <div
      className={cx(['th-item', sortKey && `th-item-${sortKey?.toLowerCase()}`, className])}
      onClick={handleClick}
    >
      <span>{children}</span>
      <span className={iconClassName}>
        {
          (activeSortKey === sortKey) ?
            direction === 'asc' ?
              <ActiveAscending />
              :
              <ActiveDescending />
            : sortKey && <InactiveSort />
        }
      </span>
    </div>
  );
};

export default ThItem;
