import { Dialog, DialogContent, Button } from 'react-md';
import cx from 'classnames';
import styles from './CSVResponseDialog.module.scss';

const CSVResponseDialog = (props) => {
  const { visible, onHide, data } = props;

  const error = data && !data.setError ? (
    <>
      <h3>There were errors found on the following rows:</h3>
      <ul className={styles.errors}>
        {data.map((err, ind) => {
          return (<li key={ind}>{`Row ${err.line} ${err.error}`}</li>);
        })}
      </ul>
      <p>Please correct the file and re-upload</p>
    </>
  ) :
    <h3>{data.setError}</h3>;

  return (
    <Dialog
      id="csv-dialog"
      className={cx('md-dialog', styles.container)}
      visible={visible}
      onRequestClose={onHide}
      role="alertdialog"
      aria-describedby="CSV upload response"
      modal
    >
      <DialogContent>
        <h3 className="dialog-title">
          CSV Parse error
        </h3>
        {error}
      </DialogContent>
      <div className="dialog-actions single">
        <Button
          className={styles.button}
          theme="primary"
          themeType="contained"
          onClick={onHide}
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default CSVResponseDialog;
